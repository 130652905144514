var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-navbar",
        { staticClass: "search-bar ordermenuone" },
        [
          _c("vs-row", { staticClass: "ordermenu" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-wrap-reverse items-center justify-between"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between"
                  },
                  _vm._l(_vm.optionsNetwork, function(data, key) {
                    return _c(
                      "b-button-group",
                      { key: key },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn-campaign-filter",
                            attrs: {
                              id: data.text + "_campaign",
                              variant: "outline-secondary",
                              pressed: data.state
                            },
                            on: {
                              click: function($event) {
                                return _vm.networkChange(data.value)
                              },
                              "update:pressed": function($event) {
                                return _vm.$set(data, "state", $event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(data.text))]
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "mb-4 mr-4 cursor-pointer flex items-center justify-between",
                    attrs: { id: "selectDate" }
                  },
                  [_c("date-select")],
                  1
                )
              ]
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-2/3 mb-base" },
          [
            _c("vx-card", { attrs: { title: "Clics en anuncios" } }, [
              _c(
                "div",
                {
                  staticClass: "p-6 pb-0",
                  attrs: { slot: "no-body" },
                  slot: "no-body"
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _vm.showPrevMonth
                      ? _c("div", [
                          _c("p", { staticClass: "mb-1 txt-last-month" }, [
                            _vm._v(_vm._s(_vm.adsClicks.labels.prevMonth))
                          ]),
                          _c(
                            "p",
                            { staticClass: "text-3xl value-last-month" },
                            [
                              _vm._v(
                                _vm._s(_vm.adsClicks.numAdsClicksPrevMonth)
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mr-6" }, [
                      _c("p", { staticClass: "mb-1 font-semibold" }, [
                        _vm._v(_vm._s(_vm.txtAdsClicksThisMonth))
                      ]),
                      _c("p", { staticClass: "text-3xl value-this-month" }, [
                        _vm._v(_vm._s(_vm.adsClicks.numAdsClicksThisMonth))
                      ])
                    ])
                  ]),
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "line",
                      height: "266",
                      options: _vm.adsClicks.chartOptions,
                      series: _vm.adsClicks.series
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/3 mb-base" },
          [
            _c(
              "vx-card",
              { attrs: { title: "Clics fraudulentos" } },
              [
                _c("template", { slot: "no-body" }, [
                  _c(
                    "div",
                    { staticClass: "mt-10" },
                    [
                      _c("vue-apex-charts", {
                        attrs: {
                          type: "radialBar",
                          height: "240",
                          options: _vm.clickFraud.chartOptions,
                          series: _vm.clickFraud.series
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "flex justify-between text-center",
                    attrs: { slot: "no-body-bottom" },
                    slot: "no-body-bottom"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-1/2 border border-solid border-grey-light border-r-0 border-b-0 border-l-0"
                      },
                      [
                        _c("p", { staticClass: "mt-4" }, [
                          _vm._v("Clics evitados")
                        ]),
                        _c(
                          "p",
                          { staticClass: "mb-4 text-3xl font-semibold" },
                          [_vm._v(_vm._s(_vm.clickFraud.numAvoidClicks))]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-1/2 border border-solid border-grey-light border-r-0 border-b-0"
                      },
                      [
                        _c("p", { staticClass: "mt-4" }, [
                          _vm._v("Ahorro estimado")
                        ]),
                        _c(
                          "p",
                          { staticClass: "mb-4 text-3xl font-semibold" },
                          [
                            _vm._v(_vm._s(_vm.clickFraud.numCost)),
                            _c("sup", { staticClass: "text-base mr-1" }, [
                              _vm._v(_vm._s(_vm.currencySymbol))
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                )
              ],
              2
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row setWidth" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.$acl.get[0] === "admin",
                expression: "this.$acl.get[0]==='admin'"
              }
            ],
            staticClass: "vx-col mb-base"
          },
          [
            _c("statistics-card-line", {
              attrs: {
                icon: "UsersIcon",
                statistic: _vm.uniqueDevices.numUniqueDevices,
                statisticTitle: "Dispositivos únicos",
                chartData: _vm.uniqueDevices,
                color: "success",
                type: "area"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col mb-base" },
          [
            _c("statistics-card-line", {
              attrs: {
                icon: "AirplayIcon",
                statistic: _vm.analyzedIPs.numAnalyzedIPs,
                statisticTitle: "IPs analizadas",
                chartData: _vm.analyzedIPs,
                color: "warning",
                type: "area"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col mb-base" },
          [
            _c("statistics-card-line", {
              attrs: {
                icon: "ShieldIcon",
                statistic: _vm.lockIPs.numLockIPs,
                statisticTitle: "IPs bloqueadas",
                chartData: _vm.lockIPs,
                color: "danger",
                type: "area"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }