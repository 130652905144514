<template>
  <date-picker class="date-select" v-model="mainDate" lang="es" :shortcuts="shortcuts" range confirm v-on:change="calendarConfirm" :first-day-of-week="1"></date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import { EventBus } from '@/event-bus'

export default {
  name: 'DateSelect',
  props: [],
  data() {
    return {
      mainDate: '',
      shortcuts: [
        {
          text: 'Hoy',
          onClick: () => {
            this.mainDate = [this.$moment().startOf('day'), this.$moment()]
            this.calendarConfirm()
          }
        },
        {
          text: 'Ayer',
          onClick: () => {
            this.mainDate = [this.$moment().subtract(1, 'days').startOf('day'), this.$moment().subtract(1, 'days').endOf('day')]
            this.calendarConfirm()
          }
        },
        {
          text: 'La semana pasada',
          onClick: () => {
            let startLastWeek = this.$moment().startOf('week').subtract(1, 'week')
            let endLastWeek = this.$moment().startOf('week').subtract(1, 'days')
            this.mainDate = [startLastWeek, endLastWeek]
            this.calendarConfirm()
          }
        },
        {
          text: 'El mes pasado',
          onClick: () => {
            let startLastMonth = this.$moment().startOf('month').subtract(1, 'month')
            let endLastMonth = this.$moment().startOf('month').subtract(1, 'days')
            this.mainDate = [startLastMonth, endLastMonth]
            this.calendarConfirm()
          }
        },
        {
          text: 'Este año',
          onClick: () => {
            this.mainDate = [this.$moment().startOf('year'), this.$moment()]
            this.calendarConfirm()
          }
        }
      ]
    }
  },
  components: {
    DatePicker
  },
  methods: {
    calendarConfirm() {
      if (this.mainDate[0]) {
        let dataDate = { startDate: this.mainDate[0], endDate: this.mainDate[1] }
        EventBus.$emit('date-select-search', dataDate)
        //this.$emit('on-search-click', dataDate)
      } else {
        EventBus.$emit('date-select-search', null)
        //this.$emit('on-search-click', null)
      }
    }
  }
}
</script>

<style scoped>
.date-select {
  margin-top: 10px;
  margin-bottom: 10px;
}
.calendar-buttons-group {
  margin-top: 6px;
}

.calendar-button {
  margin: 10px 10px 10px 10px;
}
</style>
