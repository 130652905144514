<template>
  <div>
    <vs-navbar class="search-bar ordermenuone">
      <vs-row class="ordermenu">
        <div class="flex flex-wrap-reverse items-center justify-between">
          <div class="mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between">
            <b-button-group v-bind:key="key" v-for="(data, key) in optionsNetwork">
              <b-button :id="data.text+'_campaign'" class="btn-campaign-filter" variant="outline-secondary" v-on:click="networkChange(data.value)" :pressed.sync="data.state">{{data.text}}</b-button>
            </b-button-group>
          </div>
          <div id="selectDate" class="mb-4 mr-4 cursor-pointer flex items-center justify-between">
            <date-select></date-select>
          </div>
        </div>
      </vs-row>
    </vs-navbar>

    <div class="vx-row">
      <!-- LINE CHART -->
      <div class="vx-col w-full md:w-2/3 mb-base">
        <vx-card title="Clics en anuncios">
          <div slot="no-body" class="p-6 pb-0">
            <div class="flex">
              <div v-if="showPrevMonth">
                <p class="mb-1 txt-last-month">{{adsClicks.labels.prevMonth}}</p>
                <p class="text-3xl value-last-month">{{adsClicks.numAdsClicksPrevMonth}}</p>
              </div>
              <div class="mr-6">
                <p class="mb-1 font-semibold">{{txtAdsClicksThisMonth}}</p>
                <p class="text-3xl value-this-month">{{adsClicks.numAdsClicksThisMonth}}</p>
              </div>
            </div>
            <vue-apex-charts type=line height=266 :options="adsClicks.chartOptions" :series="adsClicks.series" />
          </div>
        </vx-card>
      </div>

      <!-- RADIAL CHART -->
      <div class="vx-col w-full md:w-1/3 mb-base">
        <vx-card title="Clics fraudulentos">

          <!-- CHART -->
          <template slot="no-body">
            <div class="mt-10">
              <vue-apex-charts type=radialBar height=240 :options="clickFraud.chartOptions" :series="clickFraud.series" />
            </div>
          </template>

          <!-- DATA -->
          <div class="flex justify-between text-center" slot="no-body-bottom">
            <div class="w-1/2 border border-solid border-grey-light border-r-0 border-b-0 border-l-0">
              <p class="mt-4">Clics evitados</p>
              <p class="mb-4 text-3xl font-semibold">{{clickFraud.numAvoidClicks}}</p>
            </div>
            <div class="w-1/2 border border-solid border-grey-light border-r-0 border-b-0">
              <p class="mt-4">Ahorro estimado</p>
              <p class="mb-4 text-3xl font-semibold">{{clickFraud.numCost}}<sup class="text-base mr-1">{{currencySymbol}}</sup></p>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-row setWidth">
      <div v-show="this.$acl.get[0]==='admin'" class="vx-col mb-base">
        <statistics-card-line icon="UsersIcon" :statistic="uniqueDevices.numUniqueDevices" statisticTitle="Dispositivos únicos" :chartData="uniqueDevices" color='success' type='area'></statistics-card-line>
      </div>
      <div class="vx-col mb-base">
        <statistics-card-line icon="AirplayIcon" :statistic="analyzedIPs.numAnalyzedIPs" statisticTitle="IPs analizadas" :chartData="analyzedIPs" color='warning' type='area'></statistics-card-line>
      </div>
      <div class="vx-col mb-base">
        <statistics-card-line icon="ShieldIcon" :statistic="lockIPs.numLockIPs" statisticTitle="IPs bloqueadas" :chartData="lockIPs" color='danger' type='area'></statistics-card-line>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import statisticsOptions from './statisticsOptions.js'
import DateSelect from '@/components/DateSelect.vue'
import { EventBus } from '@/event-bus'
import Amplify from 'aws-amplify'
import utils from '@/api/utils.js'

export default {
  data() {
    return {
      currencySymbol: '',
      selectNetwork: 't',
      optionsNetwork: [
        { text: 'Todos', value: 't', state: true },
        { text: 'Search', value: 'g', state: false },
        { text: 'Display', value: 'd', state: false },
      ],
      txtThisMonth: 'Este mes',
      txtPrevMonth: 'Mes pasado',
      txtNumClicks: 'Num. clics',
      txtAdsClicksThisMonth: this.txtThisMonth,
      // AdsClick
      adsClicks: {
        labels: {
          thisMonth: this.txtThisMonth,
          prevMonth: this.txtPrevMonth
        },
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 8],
            width: [4, 2],
          },
          grid: {
            borderColor: '#e7e7e7',
          },
          legend: {
            show: false,
          },
          colors: ['#F97794', '#b8c2cc'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#7367F0', '#b8c2cc'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100]
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5
            }
          },
          xaxis: {
            labels: {
              style: {
                cssClass: 'text-grey fill-current',
              }
            },
            axisTicks: {
              show: false,
            },
            categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
            axisBorder: {
              show: false,
            },
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                cssClass: 'text-grey fill-current',
              },
              formatter: function (val) {
                return val > 999 ? (val / 1000).toFixed(1) + 'k' : val;
              }
            }
          },
          tooltip: {
            x: { show: false }
          }
        },
        numAdsClicksThisMonth: 0,
        numAdsClicksPrevMonth: 0
      },

      // ClickFraud
      clickFraud: {
        series: [0],
        chartOptions: statisticsOptions.clickFraud.chartOptions,
        numAvoidClicks: 0,
        numCost: 0,
      },

      // Unique visits
      uniqueDevices: {
        labels: {
          visits: 'Visitantes'
        },
        series: [],
        chartOptions: statisticsOptions.uniqueDevices.chartOptions,
        numUniqueDevices: 0
      },

      // Analized IPs
      analyzedIPs: {
        labels: {
          ips: 'IPs'
        },
        series: [],
        chartOptions: statisticsOptions.analyzedIPs.chartOptions,
        numAnalyzedIPs: 0
      },

      // Locked IPs
      lockIPs: {
        labels: {
          ips: 'IPs'
        },
        series: [],
        chartOptions: statisticsOptions.lockIPs.chartOptions,
        numLockIPs: 0
      },

      // Stats params
      searchStart: this.$moment().startOf('month').format(),
      searchEnd: this.$moment().endOf('month').format(),

      showPrevMonth: true,
    }
  },
  async mounted() {
    this.$vs.loading()
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.$acl.change('admin')
        this.initialGetUserData(this.$store.state.user.id)
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
    EventBus.$on('date-select-search', eventData => {
      if (eventData) {
        this.searchStart = this.$moment(eventData.startDate).startOf('day').format()
        this.searchEnd = this.$moment(eventData.endDate).endOf('day').format()
        this.showPrevMonth = false
      } else {
        this.searchStart = this.$moment().startOf('month').format()
        this.searchEnd = this.$moment().endOf('month').format()
        this.showPrevMonth = true
      }
      this.getStats()
    })
    EventBus.$on('main-account-change', () => {
      this.getStats()
    })
    EventBus.$on('user-currencySymbol', (currencySymbol) => {
      this.currencySymbol = currencySymbol
    })
  },
  methods: {
    async initialGetUserData(dataUserId) {
      const initiated = await this.$initMainData(dataUserId)
      this.tourEnabled = this.$store.state.user.tourEnabled
      if (initiated === false) {
        this.getStats()
        this.$vs.loading.close()
      } else {
        this.getStats(initiated.mainAccount)
        this.$vs.loading.close()
        this.$store.dispatch('updateUserId', {
          userId: initiated.userID
        })
      }
      if (this.$store.state.statsRaw.actual && this.$store.state.statsRaw.previous && (this.$store.state.statsRaw.actual.length > 0 || this.$store.state.statsRaw.previous.length > 0)) {
        this.paintStats(this.$store.state.statsRaw)
      }
      // FIN Get user
    },
    async getStats(dataAccount) {
      let statsParams = {
        account: this.$store.state.user.mainAccount,
        timeStart: this.$moment(this.searchStart).valueOf(),
        timeEnd: this.$moment(this.searchEnd).valueOf(),
        epoch: '1'
      }
      if (this.showPrevMonth) {
        statsParams.epoch = 'pre'
      }
      if (dataAccount) {
        statsParams.account = dataAccount
      }
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get stats
        this.$axios.get(`/homestats/${statsParams.account}/${statsParams.timeStart}/${statsParams.timeEnd}/${statsParams.epoch}`, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          this.formatNetworkClick(response.data)
        })
        // FIN Get stats
      })
    },
    formatNetworkClick(dataStats) {
      for (let customDetail of dataStats.actual) {
        if (customDetail.network === 's') {
          customDetail.network = 'g'
        }
      }
      for (let customDetailPrev of dataStats.previous) {
        if (customDetailPrev.network === 's') {
          customDetailPrev.network = 'g'
        }
      }
      this.paintStats(dataStats)
      this.$store.dispatch('storeStatsRaw', dataStats)
    },
    networkChange(e) {
      for (let i = 0; i < this.optionsNetwork.length; i++) {
        const netw = this.optionsNetwork[i]
        if (netw.value !== e) {
          this.optionsNetwork[i].state = false
        } else {
          this.optionsNetwork[i].state = true
        }
      }
      this.selectNetwork = e
      if (this.$store.state.statsRaw.actual && this.$store.state.statsRaw.actual.length > 0) {
        this.paintStats(this.$store.state.statsRaw)
      } else {
        this.getStats()
      }
    },
    numberToAmount(dataNum) {
      return dataNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    },
    paintStats(dataStats) {
      const sumStats = this.sumStats(dataStats.actual, this.selectNetwork)
      let sumLastMonth = {
        nClick: 0
      }
      // Init total values of avoid and amount save clicks
      let totalAvoidClicks = sumStats.nAvoidClickReal + sumStats.nAvoidClickProt
      let totalAmountSave = sumStats.amountSave + sumStats.amountSaveProt
      // INI Actual stats
      this.adsClicks.numAdsClicksThisMonth = sumStats.nClick
      if (totalAvoidClicks === 0) {
        this.clickFraud.numAvoidClicks = sumStats.nAvoidClick
        this.clickFraud.numCost = (sumStats.amountSave).toFixed(2)
      } else {
        if (totalAvoidClicks > this.adsClicks.numAdsClicksThisMonth) {
          this.clickFraud.numAvoidClicks = sumStats.nAvoidClickReal
          this.clickFraud.numCost = (sumStats.amountSave).toFixed(2)
        } else {
          this.clickFraud.numAvoidClicks = totalAvoidClicks
          this.clickFraud.numCost = (totalAmountSave).toFixed(2)
        }
      }

      if (this.clickFraud.numAvoidClicks > 0) {
        const nAvoidClicks = parseFloat(this.clickFraud.numAvoidClicks)
        const nAdsThisMonth = parseFloat(this.adsClicks.numAdsClicksThisMonth)
        const numRound = ((nAvoidClicks * 100) / nAdsThisMonth).toFixed(2)
        this.clickFraud.series = [numRound]
      } else {
        this.clickFraud.series = [0]
      }
      this.uniqueDevices.numUniqueDevices = sumStats.nDevices
      this.analyzedIPs.numAnalyzedIPs = sumStats.nAnalizedIps
      this.lockIPs.numLockIPs = sumStats.nLockIps
      // FIN Actual stats

      // INI Last month sum
      if (this.showPrevMonth && dataStats.previous.length > 0) {
        sumLastMonth = this.sumStats(dataStats.previous, this.selectNetwork)
        this.adsClicks.numAdsClicksPrevMonth = sumLastMonth.nClick
      }
      // FIN Last month sum

      // INI Detail
      let dataAdsClicksTM = []
      let dataAdsClicksPM = []
      let dataUniqueDevices = []
      let dataAnalyzedIps = []
      let dataLockIps = []

      // empty data
      this.adsClicks.chartOptions.xaxis.categories = []
      this.adsClicks.series = []
      this.uniqueDevices.series = []
      this.analyzedIPs.series = []
      this.lockIPs.series = []
      if (this.showPrevMonth) {
        for (let i = 1; i < 32; i++) {
          this.adsClicks.chartOptions.xaxis.categories.push(i.toString())
          dataAdsClicksTM.push(null)
          dataAdsClicksPM.push(null)
        }
      }

      // Custom detail nClick
      // Fill data
      if (dataStats && dataStats.actual && dataStats.actual.length > 0) {
        let previousDayDetail = 0
        for (let customDetail of dataStats.actual) {
          if (this.selectNetwork === 't') {
            if (customDetail.timestamp === previousDayDetail) {
              if (this.showPrevMonth) {
                const dateStat = this.$moment(customDetail.timestamp).format('DD')
                dataAdsClicksTM[parseInt(dateStat) - 1] += customDetail.nClick
              } else {
                let positionData = this.adsClicks.chartOptions.xaxis.categories.indexOf(this.$moment(customDetail.timestamp).format('DD MMMM'))
                dataAdsClicksTM[positionData] += customDetail.nClick
              }
              dataUniqueDevices[dataUniqueDevices.length - 1] += customDetail.nDevices
              dataAnalyzedIps[dataAnalyzedIps.length - 1] += customDetail.nAnalizedIps
              dataLockIps[dataLockIps.length - 1] += customDetail.nLockIps
            } else {
              previousDayDetail = customDetail.timestamp
              if (this.showPrevMonth) {
                const dateStat = this.$moment(customDetail.timestamp).format('DD')
                dataAdsClicksTM[parseInt(dateStat) - 1] = customDetail.nClick
              } else {
                this.adsClicks.chartOptions.xaxis.categories.push(this.$moment(customDetail.timestamp).format('DD MMMM'))
                dataAdsClicksTM.push(customDetail.nClick)
              }
              dataUniqueDevices.push(customDetail.nDevices)
              dataAnalyzedIps.push(customDetail.nAnalizedIps)
              dataLockIps.push(customDetail.nLockIps)
            }
          } else if (customDetail.network === this.selectNetwork) {
            if (this.showPrevMonth) {
              const dateStat = this.$moment(customDetail.timestamp).format('DD')
              dataAdsClicksTM[parseInt(dateStat) - 1] = customDetail.nClick
            } else {
              this.adsClicks.chartOptions.xaxis.categories.push(this.$moment(customDetail.timestamp).format('DD MMMM'))
              dataAdsClicksTM.push(customDetail.nClick)
            }
            dataUniqueDevices.push(customDetail.nDevices)
            dataAnalyzedIps.push(customDetail.nAnalizedIps)
            dataLockIps.push(customDetail.nLockIps)
          }
        }
      }

      // Unique devices
      this.uniqueDevices.series.push({
        name: this.uniqueDevices.labels.visits,
        data: dataUniqueDevices
      })
      // Analized IPs
      this.analyzedIPs.series.push({
        name: this.analyzedIPs.labels.ips,
        data: dataAnalyzedIps
      })
      // Locked IPs
      this.lockIPs.series.push({
        name: this.lockIPs.labels.ips,
        data: dataLockIps
      })

      if (this.showPrevMonth) {
        // Last month detail nClick
        if (dataStats && dataStats.previous && dataStats.previous.length > 0) {
          let prevMonthDetail = 0
          for (let lastMonthDetail of dataStats.previous) {
            if (this.selectNetwork === 't') {
              if (lastMonthDetail.timestamp === prevMonthDetail) {
                const dateStat = this.$moment(lastMonthDetail.timestamp).format('DD')
                dataAdsClicksPM[parseInt(dateStat) - 1] += lastMonthDetail.nClick
              } else {
                prevMonthDetail = lastMonthDetail.timestamp
                const dateStat = this.$moment(lastMonthDetail.timestamp).format('DD')
                dataAdsClicksPM[parseInt(dateStat) - 1] = lastMonthDetail.nClick
              }
            }
            else if (lastMonthDetail.network === this.selectNetwork) {
              const dateStat = this.$moment(lastMonthDetail.timestamp).format('DD')
              dataAdsClicksPM[parseInt(dateStat) - 1] = lastMonthDetail.nClick
            }
          }
        }
        // Fill data
        this.txtAdsClicksThisMonth = this.txtThisMonth
        this.adsClicks.labels.thisMonth = this.txtThisMonth
        this.adsClicks.labels.prevMonth = this.txtPrevMonth
      } else {
        this.txtAdsClicksThisMonth = `${this.$moment(this.searchStart).format('DD MMMM')} - ${this.$moment(this.searchEnd).format('DD MMMM')}`
        this.adsClicks.labels.thisMonth = this.txtNumClicks
        this.adsClicks.labels.prevMonth = this.txtPrevMonth
      }

      // Fill data
      // Ads clicks (this month)
      this.adsClicks.series.push({
        name: this.adsClicks.labels.thisMonth,
        data: dataAdsClicksTM
      })
      // Ads clicks (previous month)
      this.adsClicks.series.push({
        name: this.adsClicks.labels.prevMonth,
        data: dataAdsClicksPM
      })
      // FIN Detail
    },
    sumStats(dataStats, dataNetwork) {
      let statsRet = {
        amountSave: 0,
        amountSaveProt: 0,
        nClick: 0,
        nDevices: 0,
        nAnalizedIps: 0,
        nLockIps: 0,
        nAvoidClick: 0,
        nAvoidClickProt: 0,
        amountSaveReal: 0,
        nAvoidClickReal: 0,
      }
      for (let stat of dataStats) {
        if (dataNetwork === stat.network || dataNetwork === 't') {
          statsRet.amountSave += stat.amountSave || 0
          statsRet.amountSaveProt += stat.amountSaveProt || 0
          statsRet.nClick += stat.nClick || 0
          statsRet.nDevices += stat.nDevices || 0
          statsRet.nAnalizedIps += stat.nAnalizedIps || 0
          statsRet.nLockIps += stat.nLockIps || 0
          statsRet.nAvoidClick += stat.nAvoidClick || 0
          statsRet.nAvoidClickProt += stat.nAvoidClickProt || 0
          statsRet.nAvoidClickReal += stat.nAvoidClickReal || 0
          statsRet.amountSaveReal += stat.amountSaveReal || 0
        }
      }
      return statsRet
    },
    updateMainAccount(dataAccount) {
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get stats
        const paramsAccount = {
          accountId: dataAccount.id,
          accountName: dataAccount.name
        }
        this.$axios.put('/useraccount', paramsAccount, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        })
        // FIN Get stats
      })
    },
    changeCampaign(val) {
      this.selectNetwork = val
      this.networkChange()
    },
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    DateSelect
  }
}
</script>

<style scoped>
.search-bar {
  margin-bottom: 10px;
}
.select-campaign-type {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 34px;
}
.txt-last-month {
  font-size: 1rem;
  color: #636363;
  margin-right: 3rem;
}
.value-last-month {
  color: #636363;
}
.value-this-month {
  font-weight: bold;
}
.vs-navbar {
  justify-content: flex-start;
}
#startTour___BV_modal_outer_ {
  z-index: 90001 !important;
}
</style>
<style>
.select-campaign-type .input-select-con input {
  height: 34px;
}
.ordermenuone,
.ordermenu > div:first-child,
.ordermenuone > div:nth-child(2) {
  width: 100%;
}
.selected-button {
  font-weight: bold;
}
.campaign-buttons > div > label {
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px !important;
  border: none;
  box-shadow: none !important;
  outline: none !important;
  -moz-outline-radius: none !important;
  color: #7367f0 !important;
  background-color: white !important;
  cursor: pointer;
}
.campaign-buttons > div > label:hover {
  background-color: #2f1b7a;
  color: white;
  border-radius: 5px;
}
.campaign-buttons > div > label:active {
  color: white;
  background-color: #2f1b7a;
  border-radius: 5px;
}
.campaign-buttons > div > label:focus {
  color: #2f1b7a;
  background-color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}
.campaign-buttons > div > label.active {
  font-weight: bold;
  color: darkblue !important;
}
.btn-campaign-filter {
  margin-left: 10px;
}
#startTour___BV_modal_footer_ {
  justify-content: center !important;
  background-color: #fff;
  color: gray;
}
#startTour___BV_modal_header_ {
  background-color: #2f1b7a;
  color: white;
}
#startTour___BV_modal_header_ > button {
  color: white;
}
#startTour___BV_modal_body_ {
  background-color: #fff;
  color: gray;
}
#startTour___BV_modal_title_ {
  color: gray;
}
.startButton {
  background-color: #2f1b7a;
}
.startButton:hover {
  background-color: #6556d7;
}
.startButton:hover a {
  color: #212529 !important;
}
.setWidth {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.setWidth > div {
  flex: 1;
}
@media (max-width: 800px) {
  .setWidth {
    flex-direction: column;
  }
}
</style>