var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("date-picker", {
    staticClass: "date-select",
    attrs: {
      lang: "es",
      shortcuts: _vm.shortcuts,
      range: "",
      confirm: "",
      "first-day-of-week": 1
    },
    on: { change: _vm.calendarConfirm },
    model: {
      value: _vm.mainDate,
      callback: function($$v) {
        _vm.mainDate = $$v
      },
      expression: "mainDate"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }